import { DashboardMyReward } from '@containers/DashboardMyReward';
import { Dialog, Transition } from '@headlessui/react';
import { Button, KindButton } from '@pixiepkg/react';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import s from '../styles/UserModal.module.scss';

export const SessionsModal = ({ show, closeModal }: { show: boolean; closeModal: () => void }) => {
	// const { t } = useTranslation('common');
	const router = useRouter();

	const handleNavigate = () => {
		router.push('/experts');
	};

	return (
		<Transition appear show={show} as={Fragment}>
			<Dialog as="div" className="fixed inset-0 z-30 mt-8 overflow-y-auto" onClose={() => {}}>
				<div className="min-h-screen px-4 text-center">
					<Dialog.Overlay
						className="fixed inset-0 bg-black opacity-30"
						onClick={closeModal}
					/>

					<span className="inline-block h-screen align-middle" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-50 scale-90"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-300 scale-100"
						leaveTo="opacity-50 scale-90"
					>
						<div className={s.wrapper}>
							<div className="flex w-full items-center justify-center">
								<DashboardMyReward />
							</div>

							<div className="px-8 py-6">
								<Button
									kind={KindButton.BRAND}
									className="mx-auto  w-full bg-brand-500"
									onClick={handleNavigate}
								>
									Đặt lịch
								</Button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};
