import { api } from '@api';
import { H4, H5 } from '@pixiepkg/react';
import { loginModal } from '@shared/stores/loginModal.store';
import { sessionAtom } from '@shared/stores/session.store';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { SessionsModal } from './components/SessionsModal';
import { UserInfoModal } from './components/UserInfoModal';
import styles from './style/Gamification.module.scss';

type Questions = {
	id: string;
	name: string;
	phone: string;
	content: string;
	createdAt: number;
	updatedAt: number;
	deletedAt: number;
};

export type QuestionsResponse = {
	questions: Questions[];
	total: number;
};

export const Gamification = ({ audit = true }) => {
	const [session] = useAtom(sessionAtom);
	const [userInfoModal, setUserInfoModal] = useState({ isOpen: false, from: 'home_page' });
	const [, setOpenLoginModal] = useAtom(loginModal);
	const [sessionModal, setSessionModal] = useState(false);
	const [myQuestions, setMyQuestions] = useState<QuestionsResponse>();

	const handleOpenLoginModal = () => {
		if (!session.isSignedIn) {
			setOpenLoginModal({ isOpen: true, from: 'home_page' });
		}
	};

	useEffect(() => {
		if (session.isSignedIn) {
			api.getOwnQuestion(undefined, {
				headers: {
					Authorization: `Bearer ${session.accessToken}`,
					'Content-Type': 'application/json'
				}
			})
				.then((res) => {
					const questions = res.data || [];
					setMyQuestions(questions);
				})
				.catch((error) => console.log(error));
		}
	}, [session.isSignedIn]);

	const handleOpenModal = () => {
		if (session.isSignedIn) {
			setUserInfoModal({ isOpen: true, from: 'home_page' });
		}
	};

	const handleOpenSessionModal = () => {
		if (session.isSignedIn) {
			setSessionModal(true);
		}
	};

	return (
		<div className={classNames(styles.container, { [styles.audit]: audit })}>
			<div className="h-[244px] w-full rounded-3xl bg-gray-100 px-[56px] py-[32px] md:h-full sm:px-[24px] sm:py-[16px]">
				<H4 bold>Nhiệm vụ</H4>
				<div className="mt-[24px] flex flex-col gap-[12px]">
					<div
						className="flex cursor-pointer items-center gap-[12px]"
						onClick={handleOpenLoginModal}
					>
						{session.isSignedIn ? (
							<Image
								alt="Tick"
								width={32}
								height={32}
								src={'/images/home/rewards/tick-circle.png'}
							/>
						) : (
							<div
								className={classNames(
									'h-[28px] w-[28px] rounded-full bg-brand-50',
									styles.borderOutline
								)}
							></div>
						)}
						<H5 semiBold>1. Trở thành mentee của Pixie</H5>
					</div>
					<div
						className="flex cursor-pointer items-center gap-[12px]"
						onClick={handleOpenModal}
					>
						{myQuestions && myQuestions.total > 0 && session.isSignedIn ? (
							<Image
								alt="Tick"
								width={32}
								height={32}
								src={'/images/home/rewards/tick-circle.png'}
							/>
						) : (
							<div
								className={classNames(
									'h-[28px] w-[28px] rounded-full bg-brand-50',
									styles.borderOutline
								)}
							></div>
						)}
						<H5 className="line-clamp-1 text-ellipsis" semiBold>
							2. Điền form khảo sát, tìm chuyên gia riêng cho bản thân.
						</H5>
					</div>
					<div
						className="flex cursor-pointer items-center gap-[12px]"
						onClick={handleOpenSessionModal}
					>
						<Image
							alt="Gift.png"
							width={32}
							height={32}
							src={'/images/home/rewards/gift.png'}
						/>
						<H5 semiBold>3. Đặt lịch với chuyên gia</H5>
					</div>
				</div>
			</div>

			<UserInfoModal userInfoModal={userInfoModal} setUserInfoModal={setUserInfoModal} />
			<SessionsModal show={sessionModal} closeModal={() => setSessionModal(false)} />
		</div>
	);
};
