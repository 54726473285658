import { ArrowLeftSolid, ArrowRightSolid } from '@components/Icon';
import { TestimonialCard } from '@components/TestimonialCard';
import classNames from 'classnames';
import React from 'react';
import data from './data.json';
import styles from './styles/Reviewr.module.scss';

export const Reviewer = () => {
	const containerRef = React.useRef<HTMLDivElement>(null);

	// const { t } = useTranslation('home');

	const onRightClick = () => {
		if (
			containerRef.current &&
			Math.floor(containerRef?.current.scrollWidth - containerRef.current.scrollLeft) <=
				containerRef.current.offsetWidth
		) {
			containerRef.current.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		} else {
			if (containerRef.current)
				containerRef.current.scrollTo({
					top: 0,
					left: containerRef.current.scrollLeft + 400,
					behavior: 'smooth'
				});
		}
	};

	const onLeftClick = () => {
		if (containerRef.current && containerRef.current.scrollLeft === 0) {
			containerRef.current.scrollTo({
				top: 0,
				left: containerRef.current.scrollWidth,
				behavior: 'smooth'
			});
		} else {
			if (containerRef.current)
				containerRef.current.scrollTo({
					top: 0,
					left: containerRef.current.scrollLeft - 400,
					behavior: 'smooth'
				});
		}
	};

	return (
		<div className={classNames(styles.container, 'space-y-2')}>
			<div ref={containerRef} className={classNames(styles.reviewrContainer)}>
				{data.map((item, index) => (
					<TestimonialCard {...item} key={index} />
				))}
			</div>

			<div className="flex justify-center" style={{ scrollBehavior: 'smooth' }}>
				<button
					onClick={onLeftClick}
					className="mr-2 h-12 w-12 rounded-lg border border-solid border-gray-100
						bg-white hover:bg-gray-100 active:bg-gray-200
					"
				>
					<ArrowLeftSolid size={16} />
				</button>
				<button
					onClick={onRightClick}
					className="mr-2 h-12 w-12 rounded-lg border border-solid border-gray-100
					bg-white hover:bg-gray-100 active:bg-gray-200"
				>
					<ArrowRightSolid size={16} />
				</button>
			</div>
		</div>
	);
};
