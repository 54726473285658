import React, { useEffect, useMemo, useState } from 'react';
import { FrameExperts } from '@components/FrameExperts';
import RecommentExpretModal from '@components/RecommentExpretModal';
import { ArrowRight2Outline } from '@pixiepkg/icon';
import { Button, KindButton, SizeButton } from '@pixiepkg/react';
import { NextRecommend } from '@shared/models/NextRecommend.model';
import { ExpertsByDomain } from '@shared/models/ProfileV2.model';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Category from './Category';
import { Profile } from '@shared/models/Profile.model';
import { useSendEvent } from '@shared/hooks/useSendEvent';
import { WebinarCollectionsView } from './WebinarCollectionsView';
import { BannerContentful } from './BannerContentful';
import { NextSchedule } from './NextSchedule';
import { BecomeExpert } from '@components/BecomeExpert';
import { Testimonial } from './Testimonial';
import { FacebookMess } from '@components/FacebookMess';
import { useIsMobileAgent } from '@shared/hooks/useIsMobileAgent';
import { Gamification } from './Gamification';

interface HomeProps {
	profilesV2: ExpertsByDomain[];
	categories: string[];
}

export const Home = ({ profilesV2, categories }: HomeProps) => {
	const { sendEventGAAndBackend } = useSendEvent();
	const [activeTag, setActiveTag] = useState('');
	const { t } = useTranslation('home');
	const router = useRouter();
	const locale = router.locale; // vi, en
	const isMobileAgent = useIsMobileAgent(false);

	const domains = useMemo(
		() =>
			profilesV2.reduce((acc, item) => {
				if (item.domain) {
					acc = {
						...acc,
						[String(locale === 'vi' ? item.domainVN : item.domain)]: String(item.domain)
					};
				}
				return acc;
			}, {} as { [key: string]: string }),
		[locale]
	);

	const expertsByDomain = useMemo(() => {
		const personalizedExperts = profilesV2.filter((item) => item.domain === 'personalized');

		if (activeTag === '') {
			const allExperts = [
				...personalizedExperts.reduce<Profile[]>((prev, current) => {
					return prev.concat(current.experts);
				}, [])
			];

			// magic to remove duplicate experts when they in multiple domains
			return allExperts
				.filter((_v, index, self) => index === self.findIndex((_t) => _t.id === _v.id))
				.slice(0, 6);
		}

		const expertsMatchTag = profilesV2.find((_pv2) => {
			if (locale === 'vi') {
				return _pv2.domainVN === activeTag;
			}

			return _pv2.domain === activeTag;
		})?.experts;

		return expertsMatchTag?.slice(0, 6);
	}, [profilesV2, locale, activeTag]);

	const enDomain = useMemo(() => {
		const pv = profilesV2.filter(
			(pv2) =>
				(pv2.domain === activeTag && pv2.domain.length > 0) ||
				(pv2.domainVN === activeTag && pv2.domainVN.length > 0)
		);
		if (pv.length <= 0) {
			return null;
		}

		return pv[0].domain;
	}, [profilesV2, activeTag]);

	const [nextRecommend, setNextRecommend] = useState<NextRecommend>();

	// const Title = width > SCREEN_SIZES.md ? H2 : width > SCREEN_SIZES.xs ? H3 : H4;
	// const Tiny = width > SCREEN_SIZES.md ? H4 : width > SCREEN_SIZES.xs ? Label2 : Label3;

	const handleSeenRecommend = () => {
		if (nextRecommend) {
			if (nextRecommend.reason === 'registered_webinar') {
				const _nextRecommend: NextRecommend = {
					...nextRecommend,
					show: true,
					reason: 'joining_webinar'
				};
				localStorage.setItem('nextRecommend', JSON.stringify(_nextRecommend));
			}

			if (nextRecommend.reason === 'done_webinar') {
				localStorage.removeItem('nextRecommend');
			}
		}
	};

	useEffect(() => {
		// get next recommend
		const _nextRecommendStr = localStorage.getItem('nextRecommend');
		if (_nextRecommendStr) {
			const _nextRecommend: NextRecommend = JSON.parse(_nextRecommendStr);
			if (
				_nextRecommend.reason === 'registered_webinar' ||
				_nextRecommend.reason === 'done_webinar'
			) {
				setNextRecommend(_nextRecommend);
			}
		}

		const query = router.query;
		const extra: any = {};
		query.utm_source && (extra.utm_source = query.utm_source);
		query.utm_medium && (extra.utm_medium = query.utm_medium);
		query.utm_campaign && (extra.utm_campaign = query.utm_campaign);
		query.utm_content && (extra.utm_content = query.utm_content);
		sendEventGAAndBackend('view_home_page', extra);
	}, [router.query]);

	return (
		<>
			<BannerContentful />
			{/* <StorySlides /> */}
			<NextSchedule page="Home" />

			<Gamification />

			<section style={{ backgroundColor: '#F1F5F9' }}>
				<div className="pb-8">
					<FrameExperts
						title={t('frameExperts.customize')}
						description={
							'Các chuyên gia dày dặn kinh nghiệm luôn sẵn sàng tư vấn cho bạn.'
						}
						backgroundImage={
							isMobileAgent ? undefined : "url('/images/about/curve.svg')"
						}
						backgroundColor="#F1F5F9"
						extensionBottom={
							<Category
								categories={categories}
								onChangeCategory={(cate) => {
									setActiveTag(cate);
									sendEventGAAndBackend('click_tag', {
										tag_name: domains[cate]
									});
								}}
							/>
						}
						clickedFrom="homepage"
						experts={expertsByDomain ?? []}
						center
						onClickExpert={(id) =>
							sendEventGAAndBackend('click_expert_profile', {
								expert_id: id,
								from: 'homepage'
							})
						}
						onClickExpertBookNow={(id) => {
							sendEventGAAndBackend('click_book_now', {
								expert_id: id,
								from: 'homepage'
							});
						}}
					/>
					<div className="-mt-12 flex w-full justify-center">
						<Button
							className="flex items-center text-brand-500 hover:bg-gray-200"
							size={SizeButton.LARGE}
							rounded
							kind={KindButton.GHOST}
							onClick={() =>
								router.push({
									pathname: '/experts',
									query: {
										...router.query,
										domains: enDomain
									}
								})
							}
						>
							<>
								<span className="mr-4">See all experts</span>
								<ArrowRight2Outline size={24} />
							</>
						</Button>
					</div>
				</div>
			</section>

			<WebinarCollectionsView />

			{nextRecommend && (
				<RecommentExpretModal
					compactMode
					hideForm
					showFooter
					gradient
					isOpen={nextRecommend.show}
					clickedFrom={
						nextRecommend.reason === 'registered_webinar'
							? 'before_webinar'
							: 'after_webinar'
					}
					onClickExpert={(id) => {
						if (nextRecommend) {
							sendEventGAAndBackend('click_expert_profile', {
								expert_id: id,
								from:
									nextRecommend.reason === 'registered_webinar'
										? 'before_webinar'
										: 'after_webinar',
								webinar_id: nextRecommend.webinarId
							});
						}
						handleSeenRecommend();
					}}
					onClickExpertBookNow={(id) => {
						if (nextRecommend) {
							sendEventGAAndBackend('click_book_now', {
								expert_id: id,
								from:
									nextRecommend.reason === 'registered_webinar'
										? 'before_webinar'
										: 'after_webinar',
								webinar_id: nextRecommend.webinarId
							});
						}
					}}
					onClickSeeAll={handleSeenRecommend}
					onClose={() => {
						setNextRecommend({
							...nextRecommend,
							show: false
						});

						handleSeenRecommend();
					}}
					search={nextRecommend.search}
				/>
			)}
			{/* <Reviewr /> */}
			<Testimonial />
			<BecomeExpert />

			{expertsByDomain?.length && expertsByDomain?.length > 0 ? <FacebookMess /> : null}
		</>
	);
};
