import { api } from '@api';
import { FormInput } from '@components/FormControl';
import { Dialog, Transition } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, KindButton, Label1, Label2 } from '@pixiepkg/react';
import { useSendEvent } from '@shared/hooks/useSendEvent';
import { useTranslation } from 'next-i18next';
import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import s from '../styles/UserModal.module.scss';

interface Props {
	userInfoModal: {
		isOpen: boolean;
		from: string;
	};

	setUserInfoModal: (userInfoModal: { isOpen: boolean; from: string }) => void;
}

type IForm = {
	name: string;
	phone: string;
	content: string;
};

export const UserInfoModal = ({ userInfoModal, setUserInfoModal }: Props) => {
	const { t } = useTranslation('common');
	const { sendEventGAAndBackend } = useSendEvent();

	const schema = yup.object({
		name: yup.string().required(t('errorMessages.name.required')),

		// email: yup
		// 	.string()
		// 	.email(t('errorMessages.email.email'))
		// 	.required(t('errorMessages.email.required')),

		phone: yup
			.string()
			.required(t('errorMessages.phoneNumber.required'))
			.matches(/(84|0[3|5|7|8|9])+([0-9]{8})\b/, t('errorMessages.phoneNumber.number')),

		content: yup.string().required(t('errorMessages.content.required'))
	});
	const { control, handleSubmit } = useForm<IForm>({ resolver: yupResolver(schema) });

	function closeModal() {
		setUserInfoModal({ isOpen: false, from: 'home_page' });
	}

	const handleCreateQuestion = async (data: IForm) => {
		const { name, phone, content } = data;

		try {
			const res = await api.createQuestion({
				name,
				phone,
				content
			});

			if (res) {
				closeModal();
			}

			sendEventGAAndBackend('click_create_question', { from: 'home_page' });
		} catch (error) {
			toast.error('Create question failed');
		}
	};

	const onError = () => {
		toast.error('You must fill all required fields correctly!');
	};

	return (
		<Transition appear show={userInfoModal.isOpen} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-30 mt-8 overflow-y-auto"
				onClose={closeModal}
			>
				<div className="min-h-screen px-4 text-center">
					<Dialog.Overlay
						className="fixed inset-0 bg-black opacity-30"
						onClick={closeModal}
					/>

					<span className="inline-block h-screen align-middle" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-50 scale-90"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-300 scale-100"
						leaveTo="opacity-50 scale-90"
					>
						<div className={s.modal}>
							<div className={s.content}>
								<form className={s.form} onSubmit={(e) => e.preventDefault()}>
									<Label1>
										Điền form khảo sát, Pixie sẽ giúp bạn tìm ra chuyên gia phù
										hợp ở bất cứ đâu
									</Label1>
									<div className="flex gap-4 sm:flex-wrap">
										<div className="flex w-1/2 flex-col gap-2 sm:w-full">
											<Label2 className="self-start">Tên của bạn</Label2>
											<FormInput
												className="col-span-6"
												control={control}
												name="name"
												rules={{ required: true }}
												showErrorMessage
											/>
										</div>
										<div className="flex w-1/2 flex-col gap-2 sm:w-full">
											<Label2 className="self-start">Số điện thoại</Label2>
											<FormInput
												showErrorMessage
												type="tel"
												className="col-span-6 xs:col-span-12"
												control={control}
												name="phone"

												// rules={{ required: true, minLength: 8 }}
											/>
										</div>
									</div>
									<div className="flex w-full flex-col gap-2">
										<Label2 className="self-start">
											Chủ đề bạn đang quan tâm hay muốn giải đáp
										</Label2>
										<FormInput
											className="col-span-6"
											control={control}
											name="content"
											rules={{ required: true }}
											showErrorMessage
										/>
									</div>
								</form>
								<Button
									kind={KindButton.BRAND}
									className="mx-auto mt-[32px] w-[300px] w-full bg-brand-500 md:w-full"
									onClick={() => handleSubmit(handleCreateQuestion, onError)()}
								>
									Gửi
								</Button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};
